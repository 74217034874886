import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

const componentStyle = theme => ({
  title: {
    fontFamily: "Merriweather",
    fontSize: "1.9rem",
    fontWeight: 700,
    textTransform: "uppercase",
    margin: "5px 0 15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      lineHeight: "1.8rem",
    }
  },
  titleLetter: {
    fontSize: "2.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.0rem",
    }
  },
  dates: {
    fontSize: "1.5rem",
    fontWeight: 500,
    margin: "0 0 5px 0",
    textTransform: "uppercase",
  },
});

class Title extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.title}><span className={classes.titleLetter}>P</span>resident's <span className={classes.titleLetter}>R</span>etreat <span className={classes.titleLetter}>O</span>nline</div>
        {this.props.showDates && <div className={classes.dates}>October 16-18, 2020</div>}
      </div>
    );
  }
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  showDates: PropTypes.bool
};

export default withStyles(componentStyle)(Title);

