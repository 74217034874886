import React from "react";
import { withStyles } from "@material-ui/core";

import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { isLoggedIn } from "../services/auth";
import SEO from "../components/seo"

import { container } from "assets/jss/material-kit-pro-react.js";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import ContactUsEmbed from "../components/ContactUsEmbed/ContactUsEmbed";
import Title from "../components/SubPages/Title";

const pageStyle = theme => ({
  background: {
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    zIndex: "1",
    overflow: "visible",
    backgroundImage: "url(" + require("../assets/img/background.gif") + ")",
    backgroundPosition: "center", //"0% 50%"
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundSize: "cover",    
  },
  main: {
    zIndex: "2",
//    height: "100vh",
    paddingTop: "5vh",
//    minHeight: "750px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "140px",
    }
  },
  sections: {
    zIndex: "20",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "40px",
    color: "#ffffff"
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: "1.9rem",
    fontWeight: 200,
    ...container
  },
  subTitle: {
    fontSize: "1.5rem",
    fontWeight: 500,
    color: "#ffffff",
  },
  note: {
    fontSize: "1.1rem",
    fontWeight: 400,
    color: "#ffffff",
    "&:hover,&:focus,&:visited": {
      color: "#ffffff",
    }
  },
  link: {
    fontSize: "1.2rem",
    fontWeight: 400,
    color: "#D1833E",
    textDecoration: "underline",
    padding: "10px 0 0 0",
    "&:hover,&:focus,&:visited": {
      color: "#D1833E",
      textDecoration: "underline",
    }
  }
});

class ContactPage extends React.Component {
  componentDidMount() {
    if(!isLoggedIn()) {
      window.location = '/';
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <SEO title="President's Retreat 2020" />
        <Header
          links={<HeaderLinks />}
//          fixed
          color="transparent"
//          changeColorOnScroll={{ height: 0, color: "white" }}
          {...rest}
        />
        <div className={classes.background}></div>
        <div className={classes.main}>
          <div className={classes.sections}>
            <div className={classes.container}>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12}>
                  <Title />
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.subTitle}>We'd love to hear from you!</div>
                  <div className={classes.note}>Call: <a className={classes.note} href="tel:18777411200">877-741-1200</a></div>
                  <div className={classes.note}>Email: <a className={classes.note} href="mailto:ron@hutchcraft.com">ron@hutchcraft.com</a></div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.link}><a className={classes.link} href="https://info.hutchcraft.com/subscribe" target="__blank">Subscribe to RHM email lists</a></div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <ContactUsEmbed />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyle)(ContactPage);
