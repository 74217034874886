import React from "react"
import { withStyles } from "@material-ui/core";

const componentStyle = theme => ({
  holder: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: "20px 0 0 0",
    overflow: "hidden"
  },
  iframe: {
//    position: "absolute",
    border: "0",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "700px",
  },
});

class ContactUsEmbed extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.holder}>
        <iframe className={classes.iframe} title="Contact Us" src="https://info.hutchcraft.com/l/836243/2020-10-02/6hdsb" width="100%" height="700" type="text/html" frameborder="0" allowTransparency="true"></iframe>
      </div>
    )
  }
}

export default withStyles(componentStyle)(ContactUsEmbed);
